.ReportTaskMonthlyTopContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.ReportTaskMonthlyTopLeft {
	color: black;
	font-size: 30px;
	padding: 5px;
	margin-left: 20px;
	text-align: left;
}

.ReportTaskMonthlyTopRight {
	justify-self: end;
}

.ReportTaskMonthlyDateContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.ReportTaskMonthlyUser {
	color: black;
	font-size: 16px;
	padding: 15px;
	cursor: pointer;
	text-align: right;
}

.ReportTaskMonthlyDatePrev {
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: right;
}

.ReportTaskMonthlyDateString {
	color: black;
	font-size: 16px;
	padding: 5px;
	text-align: center;
}

.ReportTaskMonthlyDateNext {
	display: table-cell;
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: left;
}

.ReportTaskMonthlyContainer {
	padding-left: 20px;
	padding-right: 20px;
}

.ReportTaskMonthlyDayString {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
}

.ReportTaskMonthlyTaskLink {
	cursor: pointer;
}

.ReportTaskMonthlyTaskNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.ReportTaskMonthlyTaskNameItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.ReportTaskMonthlyStatusHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: center;
}

.ReportTaskMonthlyStatusItem {
	padding: 5px;
	font-size: 16px;
	width: 10vw;
	text-align: center;
}

.ReportTaskMonthlyPartHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.ReportTaskMonthlyPartItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.ReportTaskMonthlyDurationHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 20vw;
	text-align: left;
}

.ReportTaskMonthlyDurationItem {
	padding: 5px;
	font-size: 16px;
	width: 20vw;
	text-align: left;
}

