.TaskListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.TaskListToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.TaskListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.TaskListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.TaskListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.TaskListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.TaskListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.TaskListTaskNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 30vw;
	text-align: left;
}

.TaskListTaskNameItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
}

.TaskListTaskExecutorHeader {
	font-weight: bold;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.TaskListTaskExecutorItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
}

.TaskListTaskStatusHeader {
	font-size: 16px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.TaskListTaskStatusItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
}

.TaskListTaskPartHeader {
	font-size: 16px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.TaskListTaskPartItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
}

.TaskListTaskDurationHeader {
	font-size: 16px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.TaskListTaskDurationItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
}

.TaskMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.TaskMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.TaskMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.TaskMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.TaskMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.TaskMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.TaskMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.TaskInfoTitle {
	font-size: 20px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.TaskInfoItem {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.TaskInfoLink {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
	color: blue;
	cursor: pointer;
}

.TaskInfoCommentUsername {
	font-size: 14px;
	font-weight: bold;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.TaskInfoCommentText {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.TaskInfoCommentDateTime {
	font-size: 14px;
	color: grey;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.TaskInfoIconContainer {
	display: flex;
	flex-direction: row;
    margin-left: auto;
    margin-right: auto;
	width: fit-content;
}

.TaskInfoLeftDiv {
	width: 50px;
	cursor: pointer;
}

.TaskInfoRightDiv {
	width: 50px;
	cursor: pointer;
}

.TaskInfoScheduleContainer {
	display: flex;
	flex-direction: row;
    margin-left: auto;
    margin-right: auto;
	width: fit-content;
}

.TaskInfoScheduleTitle {
	font-size: 14px;
	color: black;
	font-weight: bold;
	padding: 3px;
}

.TaskInfoScheduleDateTime {
	font-size: 14px;
	color: grey;
	padding: 3px;
}

.TaskInfoScheduleDateTimeEdited {
	font-size: 14px;
	font-weight: bold;
	font-style: italic;	
	color: grey;
	padding: 3px;
}

.TaskInfoScheduleSeparator {
	font-size: 14px;
	padding: 3px;
}

.TaskInfoScheduleDuration {
	font-size: 14px;
	padding: 3px;
}

.TaskInfoScheduleDurationEdited {
	font-size: 14px;
	font-weight: bold;
	font-style: italic;	
	padding: 3px;
}

.TaskInfoScheduleEditIcon {
	font-size: 14px;
	margin-left: 7px;
	padding: 3px;
	cursor: pointer;
}

