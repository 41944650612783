.PartMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.PartMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.PartMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.PartMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.PartMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.PartMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.PartMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.PartNewFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PartNewParentLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PartNewParentInput {
	border: 0px;
	font-size: 16px;
	width: 480px;
}

.PartNewParentInput:focus {
    outline: none;
}

.PartNewNameLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PartNewNameInput {
	border: 0px;
	font-size: 16px;
	width: 480px;
}

.PartNewNameInput:focus {
    outline: none;
}

.PartMainContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
}

.PartMainLeft {
	flex: 0.3;
	//margin-left: 10px;
	//margin-right: 5px;
}

.PartMainRight {
	flex: 0.65;
	margin-left: 5px;
	margin-right: 10px;
}

.PartLeftContainer {
	text-align: left;
}

.PartLeftSearchInput {
	width: 300px;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	border: 0px;
}

.PartLeftSearchItem {
	font-size: 16px;
	padding: 10px;
	text-align: left;
	color: grey;
	cursor: pointer;
}

.PartRightName {
	font-size: 20px;
	font-weight: bold;
    padding: 10px;
	text-align: left;
}

.PartRightOwnerText {
	flex: 0.5;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	border: 0px;
}

.PartRightOwnerLink {
	flex: 0.5;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	border: 0px;
	cursor: pointer;
}

.PartRightContainer {
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.PartRightRow {
	display: flex;
	flex-direction: row;
	justify-content: left;
}

.PartRightRowCountProperty {
	display: flex;
	flex-direction: row;
	justify-content: left;
	width: 100px;
}

.PartRightText {
	//flex: 0.2;
	min-width: 200px;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	width: 200px;
}

.PartRightInput {
	flex: 0.5;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	border: 0px;
}

.PartRightIcon {
	padding-top: 7px;
	padding-left: 20px;
	border: 0px;
	cursor: pointer;
}

.PartRightFilesContainer1 {
	text-align: left;
}

.PartRightFilesDragContainer {
	text-align: left;
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	padding: 40px;
}

.PartRightFilesIcon {
	flex: 0.01;
	min-width: 30px;
	font-size: 16px;
	padding: 10px;
	text-align: left;
}

.PartRightFilesLink1 {
	flex: 0.2;
	min-width: 200px;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	cursor: pointer;
}

.PartRightFilesLink2 {
	cursor: pointer;
}

.PartRightSelectOwnerContainer1 {
	text-align: left;
}

.PropertyNewFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PropertyNewPartLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PropertyNewPartInput {
	border: 0px;
	font-size: 16px;
	width: 480px;
}

.PropertyNewPartInput:focus {
    outline: none;
}

.PropertyNewNameLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PropertyNewNameInput {
	border: 0px;
	font-size: 16px;
	width: 480px;
}

.PropertyNewNameInput:focus {
    outline: none;
}

.PropertyNewTypeContainer {
	display: flex;
	flex-direction: row;
	width: 200px;
	height: 60px;
	align-items: center;
	justify-content: left;
}

.PartRightChartCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
}

.PartRightSelectOwnerListEmailLink {
    	display: table-cell;
	color: black;
	font-size: 20px;
    	padding: 5px;
	cursor: pointer;
    	width: 400px;
	text-align: left;
}

.PartRightSelectOwnerListNameLink {
    	display: table-cell;
	color: black;
	font-size: 20px;
    	padding: 5px;
	cursor: pointer;
    	width: 400px;
	text-align: left;
}

.PropertyListToolbarContainer1 {
	display: flex;
	flex-direction: row;
	justify-content: left;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 23px;
	background-color: #dddddd;
}

.PropertyListToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 12px;
	margin: 0px 0px 0px 0px;
	min-width: 300px;
	text-align: left;
}

.PropertyListToolbarCenter {
	width: 100vw;
}

.PropertyListToolbarRight {
	min-width: 85px;
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
	text-align: right;
}

.PropertyListToolbarContainer2 {
	display: flex;
	flex-direction: row;
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
}

.PropertyListToolbarText {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.PropertyListToolbarLink {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
	cursor: pointer;
}

.PropertyListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
	cursor: pointer;
}

.PropertyListIconLink {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 50px;
	text-align: left;
}

.PropertyCodeTopContainer {
    margin-left: auto;
    margin-right: auto;
	align-items: center;
	justify-content: center;
	width: fit-content;
	//border: 1px solid;
}

.PropertyCodeContainer {
}

.PropertyCodeTextArea {
	font-size: 16px;
	width: 90vw;
}

.PropertyCodeContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

