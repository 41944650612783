.MilestoneMonthlyMainContainer1 {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
	width: fit-content;
}

.MilestoneMonthlyMainTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 3px;
}

.MilestoneMonthlyMainHeader {
	font-size: 16px;
	font-weight: bold;
	padding: 7px;
	width: 70px;
	text-align: center;
	//border: 1px solid;
}

.MilestoneMonthlyMainMilestoneHeader {
	font-size: 16px;
	font-weight: bold;
	padding: 7px;
	width: 100px;
	text-align: left;
}

.MilestoneMonthlyMainCell {
	font-size: 16px;
	padding: 7px;
	text-align: center;
	cursor: pointer;
}

.MilestoneMonthlyMainMilestoneItem {
	font-size: 16px;
	padding: 3px;
	text-align: left;
	font-weight: bold;
}

.MilestoneMonthlyMainTooltip {
	text-align: left !important;
}

