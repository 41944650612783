.Navbar {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	overflow-y: scroll;
	list-style: none;
	position: relative;
	top: 10px;
	left: -10px;
	overflow: hidden;
	width: 300px;
	z-index: 9;
	background-color: #4c4c4c;
	border-right: 1px solid #dc9a10;
	border-top: 1px solid #d2930f;
	padding: 12px 16px 12px 10px;
	transition: 0.15s ease;
}

.NavbarLink {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	height: 50px;
	color: white;
	margin: 12px 16px 12px 10px;
	cursor: pointer;
    font-size: 16px;
	font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
    margin: 0px;
    text-transform: uppercase;
    border-radius: 13px;
	border: 2px solid;
	border-color: #4c4c4c;
	align-items: center;
}

.NavbarLink:hover {
	background-color: #323232;
}

.NavbarLinkColumn1 {
	padding-top: 10px;
	padding-left: 10px;
	justify-self: start;
	width: 10px;
}

.NavbarLinkColumn2 {
	justify-self: start;
	width: 250px;
	text-align: left;
}

.NavbarBottom {
	height: 1000px;
}

