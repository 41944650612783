.part-picker-popup-content {
	background: rgb(255, 255, 255);
	width: 800px;
	height: 600px;
	padding: 0px;
	margin: 0px;
}

.part-picker-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.part-picker-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].part-picker-popup-overlay {
  background: transparent;
}

.PartPickerPopupTopContainer {
    margin: 0px;
    padding: 0px;
	border: 0px;
}

.PartPickerPopupHeaderContainer {
    margin: 0px;
    padding: 0px;
	background-color: #4c4c4c;
	color: white;
}

.PartPickerPopupHeaderText {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
	background-color: #4c4c4c;
	color: white;
}

.PartPickerPopupTree {
	justify-content: left;
	height: 400px;
}

.PartPickerPopupContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

