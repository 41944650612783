.property-picker-popup-content {
	background: rgb(255, 255, 255);
	width: 800px;
	height: 600px;
	padding: 0px;
	margin: 0px;
}

.property-picker-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.property-picker-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].property-picker-popup-overlay {
  background: transparent;
}

.PropertyPickerPopupTopContainer {
    margin: 0px;
    padding: 0px;
	border: 0px;
}

.PropertyPickerPopupHeaderContainer {
    margin: 0px;
    padding: 0px;
	background-color: #4c4c4c;
	color: white;
}

.PropertyPickerPopupHeaderText {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
	background-color: #4c4c4c;
	color: white;
}

.PropertyPickerPopupPropertyName {
    padding-left: 10px;
    padding-right: 10px;
	margin: 10px;
	text-align: left;
	cursor: pointer; 
}

.PropertyPickerPopupContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

