.edit-schedule-popup-content {
	background: rgb(255, 255, 255);
	width: 500px;
	padding: 0px;
	margin: 0px;
}

.edit-schedule-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.edit-schedule-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].enter-property-popup-overlay {
  background: transparent;
}

.TaskInfoEditScheduleTopContainer {
    margin: 0px;
    padding: 0px;
	border: 0px;
}

.TaskInfoEditScheduleHeaderContainer {
    margin: 0px;
    padding: 0px;
	background-color: #4c4c4c;
	color: white;
}

.TaskInfoEditScheduleHeaderText {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
	background-color: #4c4c4c;
	color: white;
}

.TaskInfoEditScheduleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
}

.TaskInfoEditScheduleDatePicker {
	flex: 0.05;
	width: 130px;
	font-size: 16px;
	padding-right: 20px;
	text-align: center;
}

.TaskInfoEditScheduleText {
	flex: 0.1;
	min-width: 200px;
	font-size: 18px;
	font-weight: bold;
	padding-right: 20px;
	text-align: right;
}

.TaskInfoEditScheduleRight {
	flex: 0.8;
	font-size: 16px;
	text-align: left;
	border: 0px;
	display: flex;
	flex-direction: row;
}

.TaskInfoEditScheduleDot {
	flex: 0.1;
	width: 10px;
	font-size: 16px;
	padding: 0px;
	margin: 0px;
	text-align: center;
}

.TaskInfoEditScheduleInput {
	flex: 0.1;
	width: 30px;
	font-size: 16px;
	padding: 0px;
	margin: 0px;
	text-align: center;
}

.TaskInfoEditScheduleContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

