.App {
	text-align: center;
	background-color: white;
}

.AppLogo {
}

.ListContainer1 {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.ListContainer2 {
	display: table;
    padding: 5px;
	overflow-y: scroll;
	list-style: none;
	left: 0;
	bottom: 0;
	overflow: hidden;
	margin: auto;
}

.ListContainer3 {
	width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.ListRow {
	display: flex-row;
}

.ListHeader {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.ListLink {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
	text-align: left;
}

.ListText {
    display: table-cell;
	font-size: 20px;
    padding: 5px;
    width: 20vw;
}

.ListTextLeft {
    display: table-cell;
	font-size: 20px;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.ListPrevNextLink {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 120px;
}

.ListPrevNextLinkDisabled {
    display: table-cell;
	color: grey;
	font-size: 20px;
    padding: 5px;
    width: 120px;
}

.ViewRowContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	border-style: solid;
	border-width: 1px 0px 1px 0px;
	border-color: white;
}

.ViewRowContainer:hover {
	background-color: #fafafa;
	cursor: pointer;
	border-color: #aaaaaa;
}

.ViewRowTitle {
	font-size: 12px;
	font-weight: bold;
    padding: 10px;
	margin-left: 15px;
	flex: 0.3;
	color: grey;
	text-align: left;
	text-transform: uppercase;
}

.ViewRowText {
	font-size: 20px;
    padding: 10px;
    //width: 20vw;
	flex: 0.5;
	text-align: left;
}

.ViewRowGreater {
	font-family: sans-serif;
	font-size: 20px;
	font-weight: bold;
    padding: 10px;
	color: grey;
	flex: 0.15;
	text-align: right;
}

.ViewContainer1 {
	width: 100vw;
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	justify-content: center;
}

.ViewContainer2 {
	width: 80vw;
	margin-left: 20px;
}

.ViewTopTitle {
	font-size: 30px;
	padding: 5px;
}

.ViewTopText {
	font-size:16px;
	padding: 5px;
	color: grey;
}

.ViewBlockContainer {
	width: 600px;
	min-width: 300px;
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 10px;
	justify-self: center;
	margin-left: auto;
	margin-right: auto;
}

.ViewBlockTitle {
	font-size: 24px;
	padding: 5px;
	margin-left: 15px;
	text-align: left;
}

.ViewContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	margin-right: 15px;
}

.ViewSaveButton {
  background-color: #0095ff;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.ViewSaveButton:hover,
.ViewSaveButton:focus {
  background-color: #07c;
}

.ViewSaveButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.ViewSaveButton:active {
  background-color: #0064bd;
  box-shadow: none;
}

.ViewCancelButton {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #0095ff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.ViewCancelButton:hover,
.ViewCancelButton:focus {
  background-color: #e0e0e0;
}

.ViewCancelButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.ViewCancelButton:active {
  background-color: #0064bd;
  box-shadow: none;
}

.ViewContainer {
}

.ViewItemContainer {
	width: 200px;
	margin-left: 20px;
}

.ViewItemFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	text-align: left;
	width: 530px;
}

.ViewItemLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.ViewItemInput {
	border: 0px;
	font-size: 16px;
	width: 500px;
}

.ViewItemInputDisabled {
	border: 0px;
	font-size: 16px;
	color: grey;
	width: 500px;
}

.ViewItemInput:focus {
    outline: none;
	width: 500px;
}

.ViewItemCheckbox {
	align-self: center;
}

.ViewItemCheckboxLabel {
	font-size: 18px;
	padding-left: 10px;
	margin-left: 4px;
	margin-top: 0px;
	padding: 0px;
}

.ViewItemDateString {
	font-size: 16px;
	cursor: pointer;
	padding: 0px;
	border: 0px;
	width: 500px;
}

.ViewItemDateString:focus {
    outline: none;
}

.ViewItemCompany {
	border: 0px;
	font-size: 16px;
	cursor: pointer;
	width: 500px;
}

.ViewItemCompany:focus {
    outline: none;
}

.ViewItemUser {
	border: 0px;
	font-size: 16px;
	cursor: pointer;
	width: 500px;
}

.ViewItemUser:focus {
    outline: none;
}

.ViewContainer {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.ViewItemContainer {
	width: 30vw;
    margin-left: 20px;
}

.ViewItemFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
}

.ViewItemLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.LoginBlockContainer {
	width: 600px;
	min-width: 400px;
    margin-left: auto;
    margin-right: auto;
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 10px;
}

.LoginItemInput {
	border: 0px;
	font-size: 16px;
	width: 400px;
}

.LoginItemInput:focus {
    outline: none;
}

.ChangeLogListDate {
	font-size: 24px;
	font-weight: bold;
	color: #cccccc;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.ChangeLogListEmail {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 200px;
	text-align: left;
}

.ChangeLogListPartName {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 200px;
	color: blue;
	text-align: left;
	cursor: pointer;
}

.ChangeLogListPropertyName {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 200px;
	text-align: left;
}

.ChangeLogListValueString {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 200px;
	text-align: left;
}

.ChangeLogListDateString {
    display: table-cell;
    font-size: 16px;
    padding: 5px;
    width: 100px;
    text-align: left;
}

.ChangeLogListTimeString {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 60px;
	text-align: left;
}

.ChangeLogListDelete {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 10px;
	text-align: center;
	cursor: pointer;
}

.FancyTreeContainer {
  width: 100vw;
  height: 100vh;
}

.FancyTreePopupContainer1 {
  width: 200px;
  position: absolute;
  background: #fff;
  border: 1px solid #d0d0d0;
  padding: 20px;
  top: 65px;
  left: calc(100vw - (100vw - 100%) - 246px);
}

.FancyTreePopupArrow {
	top: -8px;
	right: 20px;
	background-color: #fff;
	width: 10px;
	height: 10px;
	margin: 0 0 0 0px;
	position: absolute !important;
	border-left: 1px solid #d0d0d0;
	border-top: 1px solid #d0d0d0;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.FancyTreePopupTitle {
	font-size: 20px;
	font-weight: bold;
	position: relative;
	top: -8px;
	color: black;
	cursor: pointer;
}

.FancyTreePopupText {
	position: relative;
	top: -8px;
	color: grey;
}

.ChangeLogToolbarContainer1 {
	display: flex;
	flex-direction: row;
	justify-content: left;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 23px;
	background-color: #dddddd;
}

.ChangeLogToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 400px;
	text-align: left;
}

.ChangeLogToolbarCenter {
	width: 100vw;
}

.ChangeLogToolbarRight {
	min-width: 100px;
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
	text-align: right;
}

.ChangeLogToolbarContainer2 {
	display: flex;
	flex-direction: row;
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
}

.ChangeLogToolbarText {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.ChangeLogToolbarLink {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
	cursor: pointer;
}

.ChangeLogToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
	cursor: pointer;
}

.AboutTitle {
	font-size: 20px;
	font-weight: bold;
}

.AboutDateString {
	font-size: 16px;
	font-weight: bold;
}

.AboutInfoText {
	font-size: 16px;
}

