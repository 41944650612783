.PropertyTriggerToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.PropertyTriggerToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.PropertyTriggerToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.PropertyTriggerToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.PropertyTriggerToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.PropertyTriggerToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.PropertyTriggerToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.PropertyTriggerTopContainer {
    margin-left: auto;
    margin-right: auto;
	align-items: center;
	justify-content: center;
	width: fit-content;
	//border: 1px solid;
}

.PropertyTriggerContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.PropertyTriggerContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
}

.PropertyTriggerLeft {
	flex: 0.3;
	//margin-left: 10px;
	//margin-right: 5px;
}

.PropertyTriggerRight {
	flex: 0.65;
	margin-left: 5px;
	margin-right: 10px;
}

.PropertyTriggerLeftName {
	padding: 10px;
	margin: 0px;
	text-align: left;
	cursor: pointer;
}

.PropertyTriggerLeftNameSelected {
	padding: 10px;
	margin: 0px;
	text-align: left;
	font-weight: bold;
	cursor: pointer;
}

.PropertyTriggerRightTitleAndIcon {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: left;
    padding: 7px;
    margin-top: 10px;
}

.PropertyTriggerRightTitle {
	font-size: 20px;
	font-weight: bold;
	text-align: left;
}

.PropertyTriggerRightDeleteIcon {
	cursor: pointer;
}

.PropertyTriggerRightContainer {
	justify-content: left;
}

.PropertyTriggerRightRow {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: left;
	width: 600px;
    padding: 7px;
}

.PropertyTriggerRightRowTitle {
	text-align: left;
	width: 150px;
	//width: fit-content;
}

.PropertyTriggerRightPartName {
	text-align: left;
	width: 400px;
	cursor: pointer;
}

.PropertyTriggerRightPropertyName {
	text-align: left;
	width: 400px;
	cursor: pointer;
}

.PropertyTriggerRightMultiplier {
	text-align: left;
	width: 400px;
	border: 0px;
}

.PropertyTriggerRightDeleteIcon {
	cursor: pointer;
}
