.ReportTaskDailyTopContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.ReportTaskDailyTopTopTitle {
	color: black;
	font-size: 30px;
	padding: 5px;
	margin-left: 20px;
	text-align: left;
}

.ReportTaskDailyDateContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-self: end;
	align-items: center;
	justify-content: center;
}

.ReportTaskDailyDatePrev {
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: right;
}

.ReportTaskDailyDateString {
	color: black;
	font-size: 16px;
	padding: 5px;
	cursor: pointer;
	text-align: center;
	width: 80px;
}

.ReportTaskDailyDateNext {
	display: table-cell;
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: left;
}

.ReportTaskDailyContainer {
	padding-left: 20px;
	padding-right: 20px;
}

.ReportTaskDailyFullName {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
}

.ReportTaskDailyTaskLink {
	cursor: pointer;
}

.ReportTaskDailyTaskNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.ReportTaskDailyTaskNameItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.ReportTaskDailyStatusHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: center;
}

.ReportTaskDailyStatusItem {
	padding: 5px;
	font-size: 16px;
	width: 10vw;
	text-align: center;
}

.ReportTaskDailyPartHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.ReportTaskDailyPartItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.ReportTaskDailyDurationHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 20vw;
	text-align: left;
}

.ReportTaskDailyDurationItem {
	padding: 5px;
	font-size: 16px;
	width: 20vw;
	text-align: left;
}

