.UserPickerEmailHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}

.UserPickerNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}

.UserPickerEmail {
	padding: 5px;
	font-size: 16px;
	cursor: pointer;
}

.UserPickerName {
	padding: 5px;
	font-size: 16px;
	cursor: pointer;
}

