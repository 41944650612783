.MachineListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.MachineListToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.MachineListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.MachineListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.MachineListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.MachineListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.MachineListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.MachineListMachineNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 30vw;
	text-align: left;
}

.MachineListMachineNameItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
}

.MachineListLastDateTimeHeader {
	font-weight: bold;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.MachineListLastDateTimeItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
}

.MachineListLastCurrentHeader {
	font-size: 16px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.MachineListLastCurrentItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
}

.MachineListMachinePartHeader {
	font-size: 16px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.MachineListMachinePartItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
}

.MachineListMachineDurationHeader {
	font-size: 16px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.MachineListMachineDurationItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
}

.MachineMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.MachineMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.MachineMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.MachineMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.MachineMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.MachineMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.MachineMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.MachineInfoTitle {
	font-size: 20px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.MachineInfoItem {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.MachineInfoLink {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
	color: blue;
	cursor: pointer;
}

.MachineInfoCommentUsername {
	font-size: 14px;
	font-weight: bold;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.MachineInfoCommentText {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.MachineInfoCommentDateTime {
	font-size: 14px;
	color: grey;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.MachineInfoIconContainer {
	display: flex;
	flex-direction: row;
    margin-left: auto;
    margin-right: auto;
	width: fit-content;
}

.MachineInfoLeftDiv {
	width: 50px;
	cursor: pointer;
}

.MachineInfoRightDiv {
	width: 50px;
	cursor: pointer;
}

.MachineInfoScheduleContainer {
	display: flex;
	flex-direction: row;
    margin-left: auto;
    margin-right: auto;
	width: fit-content;
}

.MachineInfoScheduleTitle {
	font-size: 14px;
	color: black;
	font-weight: bold;
	padding: 3px;
}

.MachineInfoScheduleDateTime {
	font-size: 14px;
	color: grey;
	padding: 3px;
}

.MachineInfoScheduleDateTimeEdited {
	font-size: 14px;
	font-weight: bold;
	font-style: italic;	
	color: grey;
	padding: 3px;
}

.MachineInfoScheduleSeparator {
	font-size: 14px;
	padding: 3px;
}

.MachineInfoScheduleDuration {
	font-size: 14px;
	padding: 3px;
}

.MachineInfoScheduleDurationEdited {
	font-size: 14px;
	font-weight: bold;
	font-style: italic;	
	padding: 3px;
}

.MachineInfoScheduleEditIcon {
	font-size: 14px;
	margin-left: 7px;
	padding: 3px;
	cursor: pointer;
}

