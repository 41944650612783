.PropertyMinFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PropertyMinPartLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PropertyMinPartInput {
	border: 0px;
	font-size: 16px;
	width: 480px;
}

.PropertyMinPartInput:focus {
    outline: none;
}

.PropertyMinNameLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PropertyMinNameInput {
	border: 0px;
	font-size: 16px;
	width: 480px;
}

.PropertyMinNameInput:focus {
    outline: none;
}

