.enter-notes-popup-content {
	background: rgb(255, 255, 255);
	width: 800px;
	padding: 0px;
	margin: 0px;
}

.enter-notes-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.enter-notes-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].enter-property-popup-overlay {
  background: transparent;
}

.TaskInfoEnterNotesTopContainer {
    margin: 0px;
    padding: 0px;
	border: 0px;
}

.TaskInfoEnterNotesHeaderContainer {
    margin: 0px;
    padding: 0px;
	background-color: #4c4c4c;
	color: white;
}

.TaskInfoEnterNotesHeaderText {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
	background-color: #4c4c4c;
	color: white;
}

.TaskInfoEnterNotesPartName {
	font-size: 20px;
	font-weight: bold;
    padding: 10px;
	text-align: left;
}

.TaskInfoEnterNotesContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
	//width: 100px;
}

.TaskInfoEnterNotesText {
	flex: 0.1;
	min-width: 120px;
	font-size: 20px;
	padding: 10px;
	padding-right: 20px;
	text-align: right;
}

.TaskInfoEnterNotesInput {
	flex: 0.8;
	font-size: 16px;
	padding: 10px;
	text-align: left;
	border: 0px;
}

.TaskInfoEnterNotesContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

